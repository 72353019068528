<template>
  <div class="crud" v-loading="loadingAccounts" element-loading-text="Cargando espere por favor" :class="{ 'full' : loadingAccounts }">
    <div class="crud-content">
      <div class="header">
        <div class="title">
          <el-button type="text" icon="fas fa-arrow-left" @click="goBack"></el-button>
          <h2>{{ $t('contact-me.chat-center.social-pages.title')}}</h2>
        </div>
        <div class="buttons" v-if="accounts">
          <el-button size="small" type="primary" @click="goToNew" >{{ $t('contact-me.chat-center.social-pages.add-page') }}</el-button>
          <el-button size="small" type="primary" @click="signInWithFacebook" :loading="addingAccount" :disabled="addingAccount">
            {{ !addingAccount ? $t('contact-me.chat-center.social-pages.add-account') : $t('contact-me.chat-center.social-pages.adding-account') }}
          </el-button>
        </div>
      </div>
      <div v-if="!accounts && !loadingAccounts" class="no-account" >
         <img src="/images/facebook_messenger_logo.png" width="100" alt="Facebook Messenger Logo">
        <p>{{ $t("contact-me.chat-center.social-pages.add-your-facebook-account") }}</p>
        <p>{{ $t('contact-me.chat-center.social-pages.associate-the-Facebook-pages-with-HiperMe-and-start-assisting-your-visitor') }}</p>
        <el-button size="small" type="primary" @click="signInWithFacebook" :loading="addingAccount" :disabled="addingAccount">
          {{ !addingAccount ? $t('contact-me.chat-center.social-pages.add-account') : $t('contact-me.chat-center.social-pages.adding-account') }}
        </el-button>
      </div>
      <TheTable :rows="rows" :columns="columns" :pagination="pagination" :loading="loading" v-if="accounts">
        <template v-slot:metaAccount="scope">
          <p>{{ scope.row.metaAccount.user.name + " " + scope.row.metaAccount.user.lastname }}</p>
        </template>
        <template v-slot:pageName="scope">
          <div class="pageName">
            <a :href="scope.row.url" target="_blank">{{ scope.row.pageName }}</a>
          </div>
          <p class="pageId">{{ scope.row.pageId}}</p>
        </template>
        <template v-slot:destination="scope">
          <div class="pageName" v-if="scope.row.department || scope.row.timeCondition">
            <i class="fas fa-briefcase" v-if="scope.row.department" />
            <i class="fas fa-clock" v-else></i>
            <p> {{ scope.row.department ? scope.row.department.name : scope.row.timeCondition.name }}</p>
          </div>
        </template>
        <template v-slot:createdAt="scope">
            <p> {{getDate(scope.row.createdAt)  }}</p>
        </template>
        <template #actions="scope">
          <el-dropdown trigger="click">
            <el-button icon="fas fa-ellipsis-h" type="text"></el-button>
            <el-dropdown-menu slot="dropdown">
              <slot name="action-buttons" v-bind:row="scope.row"></slot>
              <el-dropdown-item icon="fas fa-pencil-alt" @click.native="handleEdit(scope.row)">{{ $t("common.edit") }}</el-dropdown-item>
              <el-dropdown-item icon="fas fa-trash" @click.native="handleDelete(scope.row)">{{ $t("common.delete") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </TheTable>
    </div>
    <el-dialog :visible.sync="accountLogin">
      <div v-if="accountAdded" class="account-dialog">
        <img src="@/img/success.svg" />
        <p>{{  $t('contact-me.chat-center.social-pages.account-successfully-linked') }}</p>
        <p style="word-break: break-word;">{{ $t('contact-me.chat-center.social-pages.account-successfully-linked-success')  }}<p>
          <div>
            <el-button size="small" type="primary" @click="goToNew">{{ $t('contact-me.chat-center.social-pages.add-page') }}</el-button>
            <el-button size="small" type="text" @click="accountLogin = false">{{ $t('common.maybe-later') }}</el-button>
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/api/http'
import TheTable from '@/components/crud/TheTable'
import { loadFacebookSdk, initFacebookSdk } from "@/plugins/facebook-login";
export default {
  data () {
    return {
      store: 'socialPages',
      columns: [
        { index: 'metaAccount', label: this.$t('contact-me.chat-center.social-pages.account') },
        { index: 'pageName', label: this.$t('contact-me.chat-center.social-pages.pageName') },
        { index: 'destination', label: this.$t('contact-me.chat-center.social-pages.destination') },
        { index: 'createdAt', label: this.$t('contact-me.chat-center.social-pages.addAt') },
        { index: 'actions', label: '' },
      ],
      loading: true,
      loadingAccounts: true,
      accounts: false,
      accountAdded: null,
      accountLogin: false,
      addingAccount: false
    }
  },
  components: {
    TheTable
  },
  methods: {
    login(response) {
      if (response.authResponse) {
        this.addingAccount = true
        const { accessToken, userID } = response.authResponse
        const organizationId = this.$store.getters['contactMe/getOrganizationId']
        http.post("/meta/register", { accessToken, organizationId, userId: userID  })
        .then(({ data }) => {
          this.accountAdded = data
          this.accountLogin = true
        }).finally(() => {
          this.addingAccount = false
        })
      }
    },
    signInWithFacebook() {
      window.FB.login(this.login, {scope: 'pages_manage_metadata,pages_messaging,pages_show_list'})
    },
    handleEdit(row) {
      this.$router.push({
        name : 'socialPagesEdit',
        params : {
          id : row.id + '',
          entity : row
        }
      })
    },
    async handleDelete(row) {
      try {
        const response = await this.$confirm(this.$t("contact-me.are-you-sure-you-want-to-delete", { name: row.pageName }), this.$t("common.delete"), { confirmButtonText: this.$t("common.yes"), cancelButtonText: this.$t("common.no"), type: 'warning'})
        if (response === "confirm") {
          try {
            await this.$store.dispatch(`${this.store}/destroy`, row['@id'].replace('/api/meta_pages/', ''));
            this.$message({ type: 'success', message: this.$t('contact-me.has-been-deleted-successfully', { name : row.pageName })})
          } catch (error) {
            this.$message({ type: 'error',message: this.$t("contact-me.an-error-occurred-while-trying-to-delete", { name: row.pageName }) })
          }
        }
      } catch {
        console.log("User cancel action")
      }
    },
    goToNew () {
      this.$router.push({ name: "socialPagesCreate", params: {
        account: this.accountAdded
      }})
    },
    goBack () {
      this.$router.push({ name: "contact-me-chat-center" })
    },
    async fetchMetaAccounts() {
      this.loadingAccounts = true
      try {
        const { data } = await http.get('/meta_accounts', { params: {
          ['organization.id'] : this.organizationId }
        } )
        this.accounts = data['hydra:member'] && data['hydra:member'].length > 0
      } finally {
        this.loadingAccounts = false
      }
    },
    async fetchMetaPages () {
      this.loading = true
      try {
        await this.$store.dispatch(`${this.store}/all`, { ['organization.id'] : this.organizationId })
      } finally {
        this.loading = false
      }
    },
    getDate(dateString) {
      return new Date(dateString).toLocaleDateString([], { hour: '2-digit', minute: '2-digit' })
    }
  },
  computed: {
    organizationId () {
      return this.$store.getters["contactMe/getOrganizationId"];
    },
    pagination () {
      return this.$store.getters[`${this.store}/getPagination`];
    },
    rows () {
      return this.$store.getters[`${this.store}/getData`]
    }
  },
  mounted() {
    loadFacebookSdk(document, 'script', 'facebook-jssdk').then(() => {
      initFacebookSdk(process.env.VUE_APP_META_APP_ID)
    })
  },
  async beforeMount() {
    this.loadingAccounts = true
    try {
      const { data } = await http.get('/meta_accounts', { params: {['organization.id'] : this.organizationId} } )
      this.accounts = data['hydra:member'] && data['hydra:member'].length > 0
    } finally {
      this.loadingAccounts = false
    }
  },
  async created () {
    await this.fetchMetaPages()
  }
};
</script>

<style scoped>
.title {
  display: inline-flex;
  align-items: center;
  gap: var(--column);;
}

.title h2 {
  margin: 0;
}
</style>

<style>
.pageName {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.pageName .icon {
	color: white;
  display: flex;	
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.pageName .icon.facebook {
  background-color: #3b5998 
}

.pageName .icon.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.full {
  min-width: 100vh;
}

.pageName p.pageId {
  color: grey;
  font-size: 12px;
}

.no-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.no-account p {
  text-align: center;
}

.no-account p.first-child {
  font-weight: 500
}

.account-dialog{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
</style>