export function loadFacebookSdk(d, s, id) {
  return new Promise((resolve, reject) => {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      resolve();
      return;
    }
    const js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    js.onload = resolve;  // Espera a que el SDK se cargue completamente
    js.onerror = reject;
    fjs.parentNode.insertBefore(js, fjs);
  });
}

export function initFacebookSdk(appId, version = 'v16.0') {
  console.log({ appId, version})
  window.fbAsyncInit = function () {
    try {
      // eslint-disable-next-line no-undef
      FB.init({
        appId,
        cookie: true,
        xfbml: true,
        version,
      });
      
      // eslint-disable-next-line no-undef
      FB.AppEvents.logPageView();   
    } catch (e) {
      console.error(e)
    }
  }
}